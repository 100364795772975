/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system


// Hide topbar menu
@media screen and (min-width: 992px) {
    .layout-topbar {
        display: flex;
    }
}
@media screen and (max-width: 991px) {
    .layout-topbar {
        display: none !important;
    }
}

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

@import 'assets/layout/styles/theme/theme-light/unoentrecienmil/theme.css';

// Remove padding-bottom on login page
body.uecm-no-padding, body.uecm-no-padding body {
    padding-bottom: 0 !important;
}

// Remove shadow in layout-sidebar
.layout-sidebar {
    box-shadow: none;
}

// Add padding bottom to body for preventing hide the content by tab-bar component
@media screen and (max-width: 991px) {
    body {
        padding-bottom: 80px;
    }
}

// Remove padding to main-layout
.layout-content-wrapper {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.layout-topbar {
    padding-top: 2rem;
}
.layout-topbar, .layout-content {
    padding-left: 2rem;
    padding-right: 2rem;
}


// Custom border for patient avatar
.patient-avatar.p-avatar { 
    background-color: #F5F5F5 !important;
    border: 2px solid #616161;
}
p-avatar.selected .p-avatar {
    border: 2px solid var(--primary-color); 
}

span.p-ripple.p-element.p-button.p-component.p-fileupload-choose {
    border-radius: 1.75rem !important;
    background-color: #FFF !important;
    border: 2px solid #212120 !important;
}

.custom-avatar .p-avatar{
    background-color: black !important;
}

.custom-avatar img {
    filter: brightness(0) invert(1);
}

.filter-white {
    filter: brightness(0) invert(1);
}

.color-calendar .p-button{
    background-color: white !important;
    border: 1px solid white !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: var(--primary-color) !important;
    color: black !important;
}

.app-logo-sidebar {
    width: 100px;
}

.p-datepicker table td > span.p-highlight {
    background-color: var(--primary-color) !important;
    color: var(--surface-a) !important;
}

/* Custom tree */
.p-tree {
    border: none !important;
    background: transparent !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.p-tree .p-tree-container .p-treenode {
    border-top: 2px solid #616161;
}

.p-treenode-leaf>.p-treenode-content .p-tree-toggler {
    display: none !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-treenode-content[aria-expanded="true"] {
    background-color: #212121;
    color: var(--surface-a);
} 

.p-treenode-content[aria-expanded="true"] .p-treenode-icon{
    color:var(--surface-a) !important;
}

.p-tree .p-tree-container .p-treenode { 
    padding: 0 !important;
}

ul.p-tree-container li:not(.p-treenode-leaf):first-child,
ul.p-treenode-children > .p-element:first-child .p-treenode {
    border-top: none;
}

ul.p-treenode-children > .p-element .p-treenode{
    border-top: 2px solid var(--surface-500);
}

chevrondownicon {
    color:var(--surface-a);
}

span.p-treenode-label{
    vertical-align: text-bottom !important;
}

span.p-treenode-label .pi.pi-angle-right {
    color:var(--surface-500);
}

span.p-treenode-icon.pi.pi-id-card.ng-star-inserted {
    vertical-align: middle !important;
    font-size: 1rem !important;
}

button.p-ripple.p-element.p-tree-toggler.p-link {
    vertical-align: middle !important;
}

/* Remove transparent border in all p-dialogs */
.p-dialog {
    padding: 0 !important;
}

/* Custom p-dialog */

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: var(--surface-900) !important;
    color: var(--surface-a) !important;
}

.p-accordion .p-accordion-content {
    border: none !important;
    border-radius: 0rem 0rem 0.375rem 0.375rem !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border-top: solid #000 1px !important;
    border-right: solid #000 1px !important;
    border-left: solid #000 1px !important; 
}

.without-border .p-accordion .p-accordion-content {
    border: none !important;
}

.without-background .p-accordion .p-accordion-content {
    background: none !important;
}

.with-border .p-accordion .p-accordion-content {
    border-right: solid #000 1px !important;
    border-bottom: solid #000 1px !important;
    border-left: solid #000 1px !important;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border-radius: 0.375rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    background: transparent !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: var(--surface-900) !important;
}

chevronrighticon {
    color: var(--surface-900) !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link{
    color: #212121 !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid var(--surface-300) !important;
    border-radius: 0.375rem 0.375rem 0rem 0rem !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
}
/* Custom p-confirmDialog */

/* Changed styles to p-steps acording to UECM design */
.p-steps .p-steps-item.p-highlight .p-steps-title{
    color: #009EFF !important;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    border: 1px solid #009EFF !important;
}
.p-steps .p-steps-item .p-menuitem-link {
    background: none !important;
}

.p-steps-title{
    white-space: initial !important;
    text-align: center !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    color: #9E9E9E;
}

span.p-steps-title.ng-star-inserted{
    font-weight: 700;
    font-size: 1.125rem;
}

.icon-check span.p-steps-number{
    background: #7CCAF8 !important;
    border:solid white 1px !important;
    color: white !important;
}

/* Changed styles to p-autocomplete items acording to UECM design */
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    background-color: var(--primary-color) !important;
    color: black !important;
}

// Remove shadows in sidebar and card
.layout-sidebar {
    box-shadow: none !important;
}
.border-round-4xl {
    border-radius: 1.75rem!important
}

.fc .fc-toolbar-title {
    font-size: 1.1em;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #000;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: rgba(254, 186, 0, 0.24);
}