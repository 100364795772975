
$primaryColor: #FEBA00 !default;
$primaryLightColor: #BFDBFE !default;
$primaryDarkColor: #FEBA00 !default;
$primaryDarkerColor: #FFE299 !default;
$primaryTextColor: #212121 !default;
$primary500:#3B82F6 !default;

$shade000: #ffffff;

$fontFamily: 'Karla', sans-serif;

$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

$inputPadding: 0.65rem 0.65rem;
$inputBg: #ffffff;

$dialogHeaderBg: #F8F6F2;
$overlayContentBg: #F8F6F2;

// Steps
$stepsItemBg: #F8F6F2;
$stepsItemBorder: 1px solid #9E9E9E;
$stepsItemNumberColor: #9E9E9E;
$highlightBg: #009EFF !default;

$radiobuttonIconActiveColor: #FFF;

// Primary button
$buttonHoverBg: #BF8900 !default;
$inputBorder: 0px solid #ced4da;
$buttonTextColor: $primaryTextColor;
$buttonPadding: 0.65rem 1.09rem;

// Secondary button
$secondaryButtonTextColor: #212120 !default;
$secondaryButtonBorder: 2px solid #212120 !default;
$secondaryButtonBg: $shade000 !default;
$secondaryButtonHoverBg: #212121 !default;
$secondaryButtonTextHoverColor: #ffffff;

// Link button
$linkButtonColor: #424242;
$linkButtonHoverColor: #424242;

@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import '../_variables';
@import '../../theme-base/_components';
@import '../_extensions';
@import '../../extensions/_fullcalendar';


// Remove box-shadow in buttons
@mixin remove-shadow() {
    box-shadow: none !important;
}

.p-button {
    &:focus {
        @include remove-shadow();
    }

    &.p-button-secondary:enabled:focus {
        @include remove-shadow();
    }

    &.p-button-success:enabled:focus {
        @include remove-shadow();
    }

    &.p-button-info:enabled:focus {
        @include remove-shadow();
    }

    &.p-button-warning:enabled:focus {
        @include remove-shadow();
    }

    &.p-button-help:enabled:focus {
        @include remove-shadow();
    }

    &.p-button-danger:enabled:focus {
        @include remove-shadow();
    }
}